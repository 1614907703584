const __request = require(`./__request/__request_contentType_json`);

const buyer_dom_manage_confirm_autoRecharge = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/buyer/dom/manage/confirm/autoRecharge",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_manage_confirm_autoRecharge;