import {awaitWrap} from "@/page/distributor/train/front/common/unit";

import buyer_dom_manage_collectAccountList from "@/lib/data-service/flight/buyer_dom_manage_collectAccountList";
import buyer_dom_manage_checkTransferNo from "@/lib/data-service/flight/buyer_dom_manage_checkTransferNo";
import buyer_dom_manage_confirm_autoRecharge from "@/lib/data-service/flight/buyer_dom_manage_confirm_autoRecharge";

export default {
    data() {
        const validatorAmount = (rule, value, callback) => {
            let re = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
            if (re.test(value)) {
                if (Number(value) <= 0) {
                    callback(new Error(`转账金额必须大于 0`));
                } else {
                    callback();
                }
            } else {
                callback(new Error('请输入大于0且最多可带两位小数'))
            }
        }
        return {
            loading: false,
            commitVisble: false,
            submitVisble: false,
            fromData: {
                collectAccountName: '广东胤之旅科技有限公司',
                collectAccount: '',
                amount: '',
                transferNo: '',
            },
            flowInfo: {},
            collectAccountList: [],
            usableAmount: '',
            rules: {
                collectAccountName: [{required: true, message: '请输入收款账户', trigger: 'blur'}],
                collectAccount: [{required: true, message: '请输入收款账号', trigger: 'blur'}],
                amount: [
                    {required: true, message: '请输入转账金额', trigger: 'blur'},
                    {validator: validatorAmount, trigger: 'blur'},
                ],
                transferNo: [{required: true, message: '请输入转账交易号', trigger: 'blur'}],
            }
        };
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            this.fromData = {
                collectAccountName: '广东胤之旅科技有限公司',
                collectAccount: '',
                amount: '',
                transferNo: '',
            };
            this.usableAmount = '';
            this.commitVisble = false;
            this.submitVisble = false;
            let res = await buyer_dom_manage_collectAccountList();
            this.collectAccountList = res.accountList;
            this.fromData.collectAccount = this.collectAccountList[0];
        },

        //改变收款账号
        changeCollectAccount(collectAccount) {
            this.fromData.collectAccount = collectAccount;
        },

        //查询
        async check() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.loading = true;
                    buyer_dom_manage_checkTransferNo(this.fromData).then(res => {
                        let {
                            accountName,
                            amount,
                            amountText,
                            usableAmount
                        } = res;
                        this.flowInfo = {
                            accountName,
                            amount,
                            amountText,
                            usableAmount
                        }
                        this.commitVisble = true;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                        // if (err) {
                        //     this.loading = false;
                        //     this.$confirm('查询不到该笔转账流水，请核实转账信息是否有误', '提示', {
                        //         confirmButtonText: '确定',
                        //         cancelButtonText: '取消',
                        //         type: 'warning'
                        //     });
                        //     return
                        // }
                    })
                }
            })
        },

        //取消
        goBack() {
            this.$router.replace({
                name: 'distributor-yinqianbao-back-account-management'
            });
        },

        //确定
        async submit() {
            this.loading = true;
            this.commitVisble = false;
            let [err, res] = await awaitWrap(buyer_dom_manage_confirm_autoRecharge(this.fromData))
            if (err) {
                this.loading = false;
                return;
            }
            this.usableAmount = res.usableAmount;
            this.submitVisble = true;
            this.loading = false;
        },

        //跳转至自动上账记录列表
        toList() {
            this.$router.push({
                name: 'distributor-account-recharge-list'
            });
        }
    }
};